import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import config from '../../config/config.json';
import {label} from '../../utils/getLabel';
interface IPerformaceIndex {
  data: any;
}
export const PerformanceIndex = (props: IPerformaceIndex) => {
  const {data} = props;
  const appStore = useStore($appStore);

  return (
    <div className="performances-content">
      <div className="performances-content-curve"></div>
      {data.performances.map((item: any, index: number) => (
        <div className={`performances-content-item `} key={index}>
          <div className="performances-content-item-container padLR">
            <Link to={`/${appStore.language}/performances/${item.slug}`} className="performances-content-item-image">
              {item.mainImage ? (
                <img src={item.mainImage} alt={item.title} />
              ) : (
                <svg className="icon">
                  <use xlinkHref="#klezmer-figures"></use>
                </svg>
              )}
            </Link>
            <div className="performances-content-item-texts">
              <Link to={`/${appStore.language}/performances/${item.slug}`} className="performances-content-item-title">
                {item.title}
              </Link>
              {item.subtitle && <div className="performances-content-item-subtitle">{item.subtitle}</div>}
              <div className="performances-content-item-date">({item.date})</div>

              <Link
                to={`/${appStore.language}/performances/${item.slug}`}
                className="performances-content-item-image mobile"
              >
                {item.mainImage ? (
                  <img src={item.mainImage} alt={item.title} />
                ) : (
                  <svg className="icon">
                    <use xlinkHref="#klezmer-figures"></use>
                  </svg>
                )}
              </Link>
              {item.description && (
                <Link
                  to={`/${appStore.language}/performances/${item.slug}`}
                  className="performances-content-item-text"
                  dangerouslySetInnerHTML={{__html: item.description}}
                />
              )}
              <Link to={`/${appStore.language}/performances/${item.slug}`} className="performances-content-item-more">
                {label('read-more', appStore.language)}
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

import React from 'react';
import {Link} from 'react-router-dom';
import {Nav} from './components';
import {BurgerMenu} from './components/BurgerMenu';

import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';

import './Header.scss';
export const Header = () => {
  const appStore = useStore($appStore);
  return (
    <>
      <header className="padLR-l">
        <Link to="/" className="logo">
          <svg className="icon">
            <use xlinkHref="#klezmer-logo" />
          </svg>
        </Link>
        <Nav />
        <BurgerMenu />
      </header>
      <div className={`mobileNav ${appStore.navIsOpen ? 'navIsOpen' : ''}`}>
        <Nav />
      </div>
    </>
  );
};

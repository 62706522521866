import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import config from '../../config/config.json';
import {label} from '../../utils/getLabel';
interface PerformanceItem {
  data: any;
}
export const PerformanceItem = (props: PerformanceItem) => {
  const {data} = props;
  const appStore = useStore($appStore);

  return (
    <div className="about-content performanceDetails">
      <div className="about-content-curve"></div>
      <div className={`about-content-item`}>
        <div className="about-content-item-container padLR" style={{gap: '.5rem'}}>
          {data.title && <div className="about-content-item-title">{data.title}</div>}
          {data.subtitle && <div className="about-content-item-subtitle">{data.subtitle}</div>}
          {data.date && <div className="about-content-item-date">{data.date}</div>}
          {data.mainImage && (
            <div className="about-content-item-image" style={{maxWidth: '50rem'}}>
              <img src={data.mainImage} alt={data.title} style={{width: '100%', padding: '3rem 0'}} />
            </div>
          )}
          {data.text && <div className="about-content-item-text" dangerouslySetInnerHTML={{__html: data.text}} />}
          {/* {item.images && item.images.length > 0 && (
              <div className="about-content-item-images">
                {item.images.map((image: any, index: number) => (
                  <div className={`about-content-item-images-image style-${image.style}`} key={index}>
                    <img src={image.url} alt={item.title} />
                  </div>
                ))}
              </div>
            )} */}
          {data.video1 && (
            <div className="youtube" style={{aspectRatio: '16/9'}}>
              <iframe
                width="100%"
                height="auto"
                src={`https://www.youtube.com/embed/${data.video1}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                frameBorder={0}
                title={data.title}
              ></iframe>
            </div>
          )}
          {data.video2 && (
            <div className="youtube" style={{aspectRatio: '16/9'}}>
              <iframe
                width="100%"
                height="auto"
                src={`https://www.youtube.com/embed/${data.video2}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                frameBorder={0}
                title={data.title}
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

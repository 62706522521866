import {useEffect, useState} from 'react';
import {setCurrentPage, setModalImage} from '../../store/app/events';
import './Media.scss';
export const Media = () => {
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');

  useEffect(() => {
    setCurrentPage(`media`);
  }, []);
  const tags = [
    {tag: 'Performances'},
    {tag: 'Live acts'},
    {tag: 'Tag name 1'},
    {tag: 'Tag name 2'},
    {tag: 'Tag name 3'},
    {tag: 'Tag name 4'},
  ];

  const images = [
    {
      image: 'https://picsum.photos/id/1020/700/600',
      title: 'Test image title 1',
      tags: [{tag: 'Performances'}, {tag: 'Live acts'}],
    },
    {
      image: 'https://picsum.photos/id/1021/500/700',
      title: 'Test image title 2',
      tags: [{tag: 'Performances'}, {tag: 'Tag name 1'}],
    },
    {
      image: 'https://picsum.photos/id/1022/900/600',
      title: 'Test image title 3',
      tags: [{tag: 'Tag name 1'}, {tag: 'Live acts'}],
    },
    {image: 'https://picsum.photos/id/1023/610/400', title: 'Test image title 4', tags: [{tag: 'Tag name 2'}]},
    {
      image: 'https://picsum.photos/id/1024/500/800',
      title: 'Test image title 5',
      tags: [{tag: 'Live acts'}, {tag: 'Tag name 2'}],
    },
    {image: 'https://picsum.photos/id/1026/550/600', title: 'Test image title 7', tags: [{tag: 'Live acts'}]},
    {
      image: 'https://picsum.photos/id/1032/400/400',
      title: 'Test image title 8',
      tags: [{tag: 'Tag name 3'}, {tag: 'Tag name 4'}],
    },
    {image: 'https://picsum.photos/id/1025/540/680', title: 'Test image title 6', tags: [{tag: 'Tag name 4'}]},
    {
      image: 'https://picsum.photos/id/1028/700/600',
      title: 'Test image title 9',
      tags: [{tag: 'Performances'}, {tag: 'Live acts'}, {tag: 'Tag name 4'}],
    },
    {
      image: 'https://picsum.photos/id/1029/700/600',
      title: 'Test image title 10',
      tags: [{tag: 'Performances'}, {tag: 'Live acts'}, {tag: 'Tag name 1'}, {tag: 'Tag name 2'}],
    },
    {image: 'https://picsum.photos/id/1031/700/600', title: 'Test image title 10', tags: [{tag: 'Performances'}]},
  ];

  const getTags = (tagsArray: any) => {
    var tagString = '';
    tagsArray.map((item: any) => {
      tagString = `${tagString} ${item.tag.replaceAll(' ', '-')}`;
    });
    return tagString;
  };

  const filterGallery = (tag: string) => {
    const tagString = tag.replaceAll(' ', '-');
    const items = document.querySelectorAll('.gallery-item');
    setSelectedFilter(tag);
    items.forEach((item) => {
      if (item && item.classList.contains(tagString)) {
        item.classList.add('isactive');
        item.classList.remove('ishidden');
      } else {
        item.classList.add('ishidden');
        item.classList.remove('isactive');
      }
    });
  };
  const resetFilters = () => {
    const items = document.querySelectorAll('.gallery-item');
    items.forEach((item) => {
      item.classList.remove('ishidden');
      item.classList.remove('isactive');
      setSelectedFilter('');
    });
  };
  return (
    <div className="media">
      <div className="media-top isDark">
        <div className="media-top-container padLR">
          <div className="media-top-container-texts">
            <div className="media-top-container-texts-title">Media</div>
            <div className="media-top-container-texts-text">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
            </div>
          </div>
          <div className="media-top-container-figures">
            <svg className="icon">
              <use xlinkHref="#figures" />
            </svg>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="filters">
          {tags.map((item: any, i: number) => (
            <div
              className={`tagItem ${selectedFilter === item.tag ? 'activeFilter' : ''}`}
              onClick={() => {
                if (selectedFilter === item.tag) {
                  setIsFilterOn(false);
                  resetFilters();
                } else {
                  setIsFilterOn(true);
                  filterGallery(item.tag);
                }
              }}
            >
              {item.tag}
            </div>
          ))}
        </div>
        <div className={`gallery ${isFilterOn ? 'withFilters' : ''}`}>
          {images.map((item: any, i: number) => (
            <div className={`gallery-item ${getTags(item.tags)}`} key={i}>
              <img src={item.image} alt={item.title} />
              <div className="card">
                <div className="card-texts">
                  <div className="card-texts-title" onClick={() => setModalImage(item.image)}>
                    {item.title}
                  </div>
                </div>
                <div className="tagList">
                  {item.tags.map((tag: any, index: number) => (
                    <div
                      className="tag"
                      key={index}
                      onClick={() => {
                        setIsFilterOn(true);
                        filterGallery(tag.tag);
                      }}
                    >
                      {tag.tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

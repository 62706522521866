import React from 'react';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import config from '../../config/config.json';
import {useAxios} from '../../hooks/useAxios';
import './ContactForm.scss';
export const ContactForm = () => {
  const appStore = useStore($appStore);
  const {data, error} = useAxios(`${config.apiURL}/contact`, 'POST', {language: appStore.language});

  return (
    <div className="contactForm isDark">
      {data && (
        <div className="contactForm-container padLR">
          <div className="contactForm-container-title">{data.contact.title}</div>
          <div className="contactForm-container-text" dangerouslySetInnerHTML={{__html: data.contact.text}} />
          <div className="contactForm-container-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12578.794915339251!2d23.7093957!3d37.9841596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc8e4406a67fd6324!2zzpLOuc6_zrzOt8-HzrHOvc65zrrPjCDOoM6sz4HOus6_IM6gzpvOpc6mzpE!5e0!3m2!1sen!2sgr!4v1668522436147!5m2!1sen!2sgr"
              width="100%"
              height="100%"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="PLYFA location"
            ></iframe>
          </div>
          <div className="contactForm-container-title2">{data.contact.title2}</div>
          <div className="contactForm-container-text2" dangerouslySetInnerHTML={{__html: data.contact.text2}} />
          {/* <button className="contactForm-container-button">{data.contact.btnText}</button> */}
        </div>
      )}
    </div>
  );
};

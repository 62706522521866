import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {setCurrentPage} from '../../store/app/events';
import {useAxios} from '../../hooks/useAxios';
import config from '../../config/config.json';
import {label} from '../../utils/getLabel';

import {HeroItems} from './HeroItems';
import {HeroTitle} from './HeroTitle';

import './Home.scss';
export const Home = () => {
  const appStore = useStore($appStore);
  const {data, error} = useAxios(`${config.apiURL}/home`, 'POST', {language: appStore.language});
  const [restartTitle, setRestartTitle] = useState(true);
  const [restartWords, setRestartWords] = useState(true);
  const titleTimeOut = 12000;
  const wordsTimeOut = 19000;
  const [words, setWords] = useState<any[]>();
  useEffect(() => {
    setCurrentPage(``);
  }, []);

  useEffect(() => {
    if (restartTitle && data) {
      setTimeout(() => {
        setRestartTitle(false);
      }, data.hero.hero.titleAnimationSpeed * 2);
    } else {
      setRestartTitle(true);
    }
  }, [restartTitle, data]);

  useEffect(() => {
    if (restartWords && data) {
      console.log(data.hero.words);
      setTimeout(() => {
        setRestartWords(false);
      }, data.hero.hero.wordGroupDuration * data.hero.words.length + data.hero.hero.wordFadeDuration);
    } else {
      setRestartWords(true);
    }
  }, [appStore.language, data, restartWords, words]);

  return (
    <div className="home">
      {data && (
        <div className="hero">
          <div className="hero-container">
            <div className="hero-container-video">
              <div className="hero-container-video-top"></div>
              <video src={data.hero.hero.video} autoPlay loop playsInline muted />
            </div>
            <div className="hero-container-title">
              {restartTitle && (
                <HeroTitle
                  heroTitle={[{title: data.hero.hero.title}, {title: data.hero.hero.title2}]}
                  titleAnimationSpeed={data.hero.hero.titleAnimationSpeed}
                  titleFadeSpeed={data.hero.hero.titleFadeSpeed}
                />
              )}
              {restartWords ? (
                <HeroItems
                  wordGroup={data.hero.words}
                  fadeDelay={data.hero.hero.wordFadeDuration}
                  groupDelay={data.hero.hero.wordGroupDuration}
                />
              ) : (
                <div className={`animated-texts-item`}></div>
              )}

              {/* <div>
                {data.hero.words.map((item: any, index: number) => (
                  <div className="animated-texts" key={index}>
                    <div className={`animated-texts-item item-1 ${index === currentItem ? 'isVisible' : 'isHidden'}`}>
                      {item.word1}
                    </div>
                    <div className={`animated-texts-item item-2 ${index === currentItem ? 'isVisible' : 'isHidden'}`}>
                      {item.word2}
                    </div>
                    <div
                      className={`animated-texts-dialogue item-3 ${index === currentItem ? 'isVisible' : 'isHidden'}`}
                    >
                      {item.word3}
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      )}
      {data && (
        <div className="teasers">
          {data.teasers.map((item: any, index: number) => (
            <Link to={`/${appStore.language}/${item.link}`} className="teasers-item" key={index}>
              <div className="teasers-item-hover">
                <div className="teasers-item-hover-title">{item.title}</div>
                <div className="teasers-item-hover-text">{item.text}</div>
                <div className="teasers-item-hover-link">{label('read-more', appStore.language)}</div>
                <div className={`teasers-item-hover-image ${item.style}`}>
                  <img src={item.image} alt="About" />
                </div>
              </div>
              <div className="teasers-item-content">
                <div className="teasers-item-content-title">{item.title}</div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {Loader} from '../Loader';
import {useNavigate} from 'react-router-dom';

import {Modal} from '../';

import './Layout.scss';
import {ModalMedia} from '../ModalMedia/ModalMedia';
export const Layout = () => {
  const appStore = useStore($appStore);
  const navigator = useNavigate();
  const location = sessionStorage.getItem('location');
  // const [modalIsOpened, setModalIsOpened] = useState(false);

  useEffect(() => {
    if (location) {
      const parsedLocation = JSON.parse(location);
      const page = parsedLocation.page;
      navigator(`${appStore.language}/${page}`);
    }
  }, [appStore.language]);

  // useEffect(() => {
  //   if (!modalIsOpened && !sessionStorage.getItem('downloadModal')) {
  //     setModalIsOpened(true);
  //   } else {
  //     // setModalIsOpened(false);
  //   }
  // }, [modalIsOpened]);

  return (
    <div
      className={`layout ${appStore.isDark ? 'isDark' : ''} ${appStore.isLoading ? 'loading' : ''} ${
        appStore.isMobile ? 'isMobile' : ''
      }`}
    >
      <Header />
      <div className={`main`}>
        <Outlet />
      </div>
      <Footer />
      {appStore.isLoading && <Loader />}
      <Modal />
      <ModalMedia />
    </div>
  );
};

import {useStore} from 'effector-react';
import React from 'react';
import {Link} from 'react-router-dom';
import {setLanguage} from '../../../store/app/events';

import {$appStore} from '../../../store/app/store';

export const NavLang = () => {
  const appStore = useStore($appStore);
  return (
    <div className="langItems">
      <div className="langItems-item" onClick={() => setLanguage(appStore.language === 'el' ? 'en' : 'el')}>
        <div className="">{appStore.language === 'el' ? 'EN' : 'ΕΛ'}</div>
        <div className="langItems-item-arrow">
          <svg className="icon">
            <use xlinkHref="#icon-arrow" />
          </svg>
        </div>
      </div>
      {/* <div className="langItems-item">ΕΝ</div> */}
    </div>
  );
};

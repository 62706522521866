import {useState} from 'react';

import './Modal.scss';

export const Modal = () => {
  const [modalIsOpened, setModalIsOpened] = useState(true);
  return (
    <div className={`modal ${!modalIsOpened ? 'minimize' : ''}`}>
      <div className="modal-body">
        <a href="https://webqr.com/" target="_blank" rel="noreferrer">
          Click here to scan your LP qr code
        </a>
      </div>
      <button
        className="modal-close"
        onClick={() => {
          setModalIsOpened(!modalIsOpened);
        }}
      >
        <span></span>
        <span></span>
      </button>
    </div>
  );
};

import React, {useEffect} from 'react';
import {useParams} from 'react-router';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {setCurrentPage, setFooterIsDark} from '../../store/app/events';
import {useAxios} from '../../hooks/useAxios';
import config from '../../config/config.json';
import './Generic.scss';

export const Generic = () => {
  const appStore = useStore($appStore);
  const {slug} = useParams();
  const {data, error} = useAxios(`${config.apiURL}/pages`, 'POST', {language: appStore.language, slug: slug}, slug);

  useEffect(() => {
    setFooterIsDark(false);
    setCurrentPage(`page/${slug}`);
  }, [slug]);

  return (
    <div className="pages">
      {data && slug && (
        <div className="pages-container">
          <h1>{data.page.title}</h1>
          <div className="text" dangerouslySetInnerHTML={{__html: data.page.text}} />
        </div>
      )}
    </div>
  );
};

import {AppState} from './types';
import {AppDomain} from './domain';
import {
  setLanguage,
  setLanguages,
  setNavIsOpen,
  setCurrentPage,
  setIsLoading,
  setTitle,
  setDescription,
  setIsMobile,
  setIsDark,
  setFooterIsDark,
  setModalImage,
} from './events';
const localLanguage = sessionStorage.getItem('location');
const initialState: AppState = {
  languages: [],
  language: localLanguage !== null ? JSON.parse(localLanguage).lang : 'el',
  navIsOpen: false,
  currentPage: null,
  isLoading: false,
  siteTitle: '',
  siteDescription: '',
  isMobile: false,
  isDark: false,
  footerIsDark: false,
  modalImage: '',
};
export const $appStore = AppDomain.store<AppState>(initialState)
  .on(setModalImage, (state, value) => {
    return {
      ...state,
      modalImage: value,
    };
  })
  .on(setLanguage, (state, language) => {
    const location = sessionStorage.getItem('location');
    if (!location) {
      sessionStorage.setItem('location', JSON.stringify({lang: language, page: '/'}));
    } else {
      const page = JSON.parse(location).page;
      sessionStorage.setItem('location', JSON.stringify({lang: language, page: page}));
    }
    return {
      ...state,
      language: language,
    };
  })
  .on(setIsMobile, (state, value) => {
    return {
      ...state,
      isMobile: value,
    };
  })
  .on(setFooterIsDark, (state, value) => {
    return {
      ...state,
      footerIsDark: value,
    };
  })
  .on(setIsDark, (state, value) => {
    return {
      ...state,
      isDark: value,
    };
  })

  .on(setCurrentPage, (state, currentPage) => {
    const location = sessionStorage.getItem('location');
    if (!location) {
      sessionStorage.setItem('location', JSON.stringify({lang: 'en', page: currentPage}));
    } else {
      const language = JSON.parse(location).lang;
      sessionStorage.setItem('location', JSON.stringify({lang: language, page: currentPage}));
    }
    return {
      ...state,
      currentPage: currentPage,
    };
  })
  .on(setNavIsOpen, (state, value) => {
    return {
      ...state,
      navIsOpen: value,
    };
  })
  .on(setIsLoading, (state, value) => {
    return {
      ...state,
      isLoading: value,
    };
  })
  .on(setLanguages, (state, languages) => {
    return {
      ...state,
      languages: languages,
    };
  })

  .on(setTitle, (state, value) => {
    return {
      ...state,
      siteTitle: value,
    };
  })
  .on(setDescription, (state, value) => {
    return {
      ...state,
      siteDescription: value,
    };
  });

// $appStore.watch((v) => {
//   console.log(v);
// });

export default $appStore;

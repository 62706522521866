import {CSSProperties, useEffect, useState} from 'react';
import './HeroItems.scss';

interface IHeroItems {
  fadeDelay: number;
  groupDelay: number;
  wordGroup: any;
}

export const HeroItems = (props: IHeroItems) => {
  const {fadeDelay, groupDelay, wordGroup} = props;

  const [restart, setRestart] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setRestart(true);
    }, groupDelay * wordGroup.length + fadeDelay);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (restart) {
      setTimeout(() => {
        setRestart(false);
      }, fadeDelay);
    }
  }, [restart]);

  const groupCss = (index: number): CSSProperties => {
    return {
      animationDelay: `${groupDelay * (index + 1)}ms`,
      animationDuration: `${fadeDelay}ms`,
      opacity: 1,
    };
  };

  const wordCss = (group: number, index: number): CSSProperties => {
    const groupTime = groupDelay * group;
    const wordIndex = index === 1 ? 1 : 0;
    const fadeTime = fadeDelay * wordIndex;

    return {
      animationDelay: `${groupTime + fadeTime}ms`,
      animationDuration: `${fadeDelay}ms`,
    };
  };
  return (
    <div className="animated-texts">
      {!restart && (
        <div>
          {wordGroup.map((item: any, index: number) => (
            <div className={`animated-texts-item wordgroup-${index + 1}`} style={groupCss(index)} key={index}>
              {Object.keys(item).map((key: any, i: number) => (
                <div className={`word-${i + 1}`} key={i}>
                  <span style={wordCss(index, i)}>{item[key]}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

import './HeroTitle.scss';
interface IHeroTitle {
  heroTitle: any[];
  titleAnimationSpeed: number;
  titleFadeSpeed: number;
}
export const HeroTitle = (props: IHeroTitle) => {
  const {heroTitle, titleAnimationSpeed, titleFadeSpeed} = props;
  console.log(heroTitle);
  return (
    <div className="hero-container-title-top">
      {heroTitle.map((item: any, i: number) => (
        <div
          className={`titleGroup-${i + 1}`}
          style={{animationDelay: `${titleAnimationSpeed}ms`, animationDuration: `${titleFadeSpeed}ms`}}
          key={i}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

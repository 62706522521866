import {useStore} from 'effector-react';
import {useEffect} from 'react';

import useWindowSize from '../../hooks/useWindowSize';
import {setModalImage} from '../../store/app/events';
import $appStore from '../../store/app/store';

import './ModalMedia.scss';

export const ModalMedia = () => {
  const appStore = useStore($appStore);
  const {height} = useWindowSize();
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        setModalImage(null);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <div className={`modalMedia ${appStore.modalImage ? 'isOpened' : ''}`} onClick={() => setModalImage(null)}>
      {appStore.modalImage && (
        <div className="modalMedia-inner">
          <img className="modalMedia-inner-image" src={`${appStore.modalImage}`} alt="" />
          <button className="modalMedia-inner-close">
            <span></span>
            <span></span>
          </button>
        </div>
      )}
    </div>
  );
};

import {Route, Routes} from 'react-router';
import {HelmetProvider} from 'react-helmet-async';

import ScrollToTop from './utils/ScrollToTop';

import {Layout, Seo, CacheBuster} from './components';

import {Home, About, SmallTragedies, Performances, Generic, SmallTragediesTrack, Media} from './pages';

import {ReactComponent as UISvg} from './assets/ui.svg';

import './styles/main.scss';

function App() {
  return (
    <CacheBuster>
      <HelmetProvider>
        <div className="App">
          <Seo />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path={'/:lang/'} element={<Home />} />
              <Route path={'/:lang/small-tragedies'} element={<SmallTragedies />} />
              <Route path={`/:lang/small-tragedies/:slug`} element={<SmallTragediesTrack />} />
              <Route path={`/:lang/about`} element={<About />} />
              <Route path={`/:lang/media`} element={<Media />} />
              <Route path={`/:lang/performances`} element={<Performances />} />
              <Route path={`/:lang/performances/:slug`} element={<Performances />} />
              <Route path={`/:lang/page/:slug`} element={<Generic />} />
            </Route>
          </Routes>
          <UISvg />
          {/* <Version /> */}
        </div>
      </HelmetProvider>
    </CacheBuster>
  );
}

export default App;

import axios from 'axios';
import {useStore} from 'effector-react';
import {useState, useRef, useEffect} from 'react';
import {setIsLoading} from '../store/app/events';
import $appStore from '../store/app/store';

export const useAxios = (url: string, method: string, payload: any, slug?: string) => {
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();
  const [error, setError] = useState('');
  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const response = await axios.request({
          data: payload,
          signal: controllerRef.current.signal,
          method,
          url,
        });

        setData(response.data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [appStore.language, slug]);

  return {cancel, data, error};
};

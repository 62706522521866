import React, {useEffect} from 'react';
import {setCurrentPage, setFooterIsDark} from '../../store/app/events';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import config from '../../config/config.json';
import {useAxios} from '../../hooks/useAxios';
import {useParams} from 'react-router-dom';
import {PerformanceIndex} from './PerformanceIndex';
import {PerformanceItem} from './PerformanceItem';

import './Performances.scss';

export const Performances = () => {
  const {slug} = useParams();

  const appStore = useStore($appStore);
  const {data, error} = useAxios(`${config.apiURL}/performances`, 'POST', {language: appStore.language});

  useEffect(() => {
    setFooterIsDark(true);
    setCurrentPage(`performances`);
  }, []);

  return (
    <div className="performances">
      {data && (
        <div className="performances-top isDark">
          <div className="performances-top-container padLR">
            <div className="performances-top-container-texts">
              <div className="performances-top-container-texts-title">Performances</div>
              <div
                className="performances-top-container-texts-text"
                dangerouslySetInnerHTML={{__html: data.performanceText.text}}
              />
            </div>
            <div className="performances-top-container-figures">
              <svg className="icon">
                <use xlinkHref="#figures" />
              </svg>
            </div>
          </div>
        </div>
      )}
      {data && !slug && <PerformanceIndex data={data} />}
      {data && slug && <PerformanceItem data={data.performances.filter((item: any) => item.slug === slug)[0]} />}
    </div>
  );
};

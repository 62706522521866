import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import {useNavigate, useParams} from 'react-router';
import {useAxios} from '../../hooks/useAxios';
import {setCurrentPage, setFooterIsDark} from '../../store/app/events';
import $appStore from '../../store/app/store';
import config from '../../config/config.json';

import './SmallTragediesTrack.scss';

export const SmallTragediesTrack = () => {
  const {slug} = useParams();
  const appStore = useStore($appStore);
  const {data, error} = useAxios(`${config.apiURL}/getTrack`, 'POST', {slug: slug, language: appStore.language});
  const navigate = useNavigate();

  useEffect(() => {
    setFooterIsDark(true);
  }, []);

  useEffect(() => {
    setCurrentPage(`small-tragedies/${slug}`);
  }, [slug]);

  return (
    <div className="albumTrack">
      {data && data.track && (
        <div className={`track ${data.track.isDark ? 'isDark' : ''}`}>
          <div className="row rowTitle">
            <div className={`row-title ${data.track.isDark ? 'isDark' : ''}`}>
              <div className="row-title-order">{data.track.trackOrder}.</div>
              <div className="row-title-text">
                {data.track.title}
                {data.track.trackComposer && (
                  <div className="row-title-text-composer">
                    <span>{data.track.trackComposer}</span>
                  </div>
                )}
              </div>
              <Link to={`/${appStore.language}/small-tragedies`} className="back">
                Back
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="row-content">
              {data.track.youtubeId && (
                <div className="row-content-youtube">
                  <iframe
                    width="100%"
                    height="auto"
                    src={`https://www.youtube.com/embed/${data.track.youtubeId}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    frameBorder={0}
                    title={data.track.title}
                  ></iframe>
                </div>
              )}

              {data.track.info && (
                <div className="row-content-info" dangerouslySetInnerHTML={{__html: data.track.info}} />
              )}
              <div className="listenOn">
                <a
                  href={data.track.trackPreview}
                  className=""
                  target="_blank"
                  rel="noreferrer"
                  title={`Listen "${data.track.title}" on Spotify`}
                >
                  <div>Listen on</div>
                  <img
                    src="https://klezmeryunan.com/media/icons/spotify.png"
                    alt={`Listen "${data.track.title}" on Spotify`}
                  />
                </a>
              </div>
              {data.track.extraTitle && (
                <div className={`row-content-title ${data.track.isDark ? 'isDark' : ''}`}>{data.track.extraTitle}</div>
              )}
              {data.track.extraText && (
                <div className="row-content-text" dangerouslySetInnerHTML={{__html: data.track.extraText}} />
              )}

              {data.track.lyrics && (
                <div className="row-content-lyrics" dangerouslySetInnerHTML={{__html: data.track.lyrics}} />
              )}

              {data.track.trackImages && data.track.trackImages.length > 0 && (
                <div className="row-content-images">
                  {data.track.trackImages.map((image: any, index: number) => (
                    <div className={`row-content-images-image style-${image.imageStyle}`} key={index}>
                      <img src={image.imageUrl} alt={data.title} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

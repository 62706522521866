import {useStore} from 'effector-react';
import React from 'react';
import {Link} from 'react-router-dom';
import $appStore from '../../store/app/store';

import './Footer.scss';
export const Footer = () => {
  const appStore = useStore($appStore);

  return (
    <footer>
      <div className={`footer-1 ${appStore.footerIsDark ? 'footerDark' : ''}`}>
        <div className={`social  `}>
          <a
            className="social-item"
            href="https://www.youtube.com/channel/UC965dTQeTf3witZDjmLQYFw"
            target="_blank"
            rel="noreferrer"
          >
            <div className="social-item-icon">
              <svg className="icon">
                <use xlinkHref="#icon-youtube" />
              </svg>
            </div>
            <div className="social-item-text">Find us on Youtube</div>
          </a>

          <div className="social-item">
            <div className="social-item-klezmer">
              <svg className="icon">
                <use xlinkHref="#klezmer-figures" />
              </svg>
            </div>
          </div>

          <a className="social-item" href="https://www.facebook.com/KlezmerYunan/" target="_blank" rel="noreferrer">
            <div className="social-item-icon">
              <svg className="icon">
                <use xlinkHref="#icon-facebook" />
              </svg>
            </div>
            <div className="social-item-text">Join us on Facebook</div>
          </a>
        </div>
      </div>
      <div className="footer-2 padLR-l">
        <div className="footer-2-legal">
          <div className="copyright">{`© ${new Date().getFullYear()} Klezmer Yunan All Rights Reserved`}</div>
          <div className="footer-nav">
            <Link to={`/${appStore.language}/page/terms-of-use`} className="footer-nav-item">
              Terms οf Use
            </Link>
            <Link to={`/${appStore.language}/page/privacy-policy`} className="footer-nav-item">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

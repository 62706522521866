import {useStore} from 'effector-react';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAxios} from '../../hooks/useAxios';
import {setCurrentPage, setFooterIsDark} from '../../store/app/events';
import {$appStore} from '../../store/app/store';
import config from '../../config/config.json';
import {scrollTo} from '../../utils/scrollTo';
import {label} from '../../utils/getLabel';

import './SmallTragedies.scss';

export const SmallTragedies = () => {
  const appStore = useStore($appStore);
  const [tracks, setTracks] = useState<any>();
  const [showVideo, setShowVideo] = useState(false);
  const {data, error} = useAxios(`${config.apiURL}/getAlbum`, 'POST', {language: appStore.language});

  useEffect(() => {
    setFooterIsDark(false);
    setCurrentPage(`small-tragedies`);
  }, []);

  useEffect(() => {
    data && setTracks(data.album.sides[0].tracks.concat(data.album.sides[1].tracks));
  }, [data]);

  const scroll = (id: string) => {
    var track = document.getElementById(id) as HTMLElement;
    window.scrollTo(0, track.getBoundingClientRect().top);
  };

  return (
    <div className="album">
      {data && tracks && (
        <div className="album-inner">
          <div className="album-head isDark">
            <div className="album-head-container padLR">
              <div className="album-head-title">{data.album.title}</div>
              <div className="album-head-topRow">
                <div className="album-head-topRow-cover">
                  <img src={data.album.cover} alt={data.album.title} />
                </div>
                <div className="album-head-topRow-coverTracks">
                  {data.album.sides.map((side: any, i: number) => (
                    <div className="album-head-topRow-coverTracks-sides" key={i}>
                      <div className="album-head-topRow-coverTracks-sides-side">{side.side}</div>
                      <div className="album-head-topRow-coverTracks-sides-tracks" key={i}>
                        {side.tracks.length > 0 &&
                          side.tracks.map((track: any, i: number) => (
                            <div
                              // to={`/${appStore.language}/small-tragedies/${track.slug}`}
                              className="album-head-topRow-coverTracks-sides-tracks-track"
                              key={i}
                              onClick={() => {
                                scrollTo({id: track.slug});
                              }}
                            >
                              {`${track.trackOrder}. ${track.title}`}
                              <span>{track.trackTime}</span>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                  <div className="album-head-topRow-coverTracks-title">Listen on:</div>
                  <div className="album-head-topRow-coverTracks-icons">
                    <a
                      href="https://open.spotify.com/album/40NyFdzPCiU7eaNk6tSW15?referral=labelaffiliate&utm_source=1011lwwJM82G&utm_medium=Indie_Amuse&utm_campaign=labelaffiliate"
                      className="spotify-icon"
                      target="_blank"
                      rel="noreferrer"
                      title="Listen on Spotify"
                    >
                      <img src="https://klezmeryunan.com/media/icons/spotify.png" alt="Listen on Spotify" />
                    </a>
                    <a
                      href="https://music.apple.com/us/album/small-tragedies/1657104821"
                      className="apple-music-icon"
                      target="_blank"
                      rel="noreferrer"
                      title="Listen on Apple music"
                    >
                      <img src="https://klezmeryunan.com/media/icons/apple-music.png" alt="Listen on Apple music" />
                    </a>
                    <a
                      href="https://music.youtube.com/playlist?list=OLAK5uy_k5tAqFX77N_YBRnnJCBmPZIJFlFcsu1uY"
                      className="youtube-music-icon"
                      target="_blank"
                      rel="noreferrer"
                      title="Listen on Youtube music"
                    >
                      <img src="https://klezmeryunan.com/media/icons/youtube-music.png" alt="Listen on Youtube music" />
                    </a>
                    <a
                      href="https://www.deezer.com/en/album/382007767"
                      className="deezer-icon"
                      target="_blank"
                      rel="noreferrer"
                      title="Listen on Deezer"
                    >
                      <img src="https://klezmeryunan.com/media/icons/deezer.png" alt="Listen on Deezer" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="album-head-texts">
                <div className="album-head-texts-text1" dangerouslySetInnerHTML={{__html: data.album.text1}} />
                <div className="album-head-texts-text2" dangerouslySetInnerHTML={{__html: data.album.text2}} />
                {/* <div className="album-head-texts-readMore">{data.album.readMoreText}</div> */}
                <div className="album-head-texts-text3" dangerouslySetInnerHTML={{__html: data.album.text3}} />
              </div>
            </div>
          </div>
          <div className="album-tracks">
            <div className="album-tracks-curve"></div>
            <div className="album-tracks-title container padLR">{label('songs', appStore.language)}</div>
            <div className="album-tracks-inner">
              <div className="image-sun">
                <svg className="icon">
                  <use xlinkHref="#image-sun" />
                </svg>
              </div>

              <div className="row" id={tracks[0].slug}>
                <div className="album-tracks-track container padLR">
                  <div className="trackTexts">
                    <div className="album-tracks-track-number">1.</div>
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[0].slug}`}
                      className="album-tracks-track-title"
                    >
                      {tracks[0].title}
                    </Link>
                    {tracks[0].trackComposer && (
                      <div className="album-tracks-track-composer">{tracks[0].trackComposer}</div>
                    )}
                    <div
                      className="album-tracks-track-text width-m"
                      dangerouslySetInnerHTML={{__html: tracks[0].shortDescription}}
                    />
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[0].slug}`}
                      className="album-tracks-track-link"
                    >
                      {tracks[0].youtubeId ? label('videoInfo', appStore.language) : label('info', appStore.language)}
                    </Link>
                  </div>
                </div>
              </div>
              <div id={tracks[1].slug} />
              <div className="row" onMouseEnter={() => setShowVideo(true)} onMouseLeave={() => setShowVideo(false)}>
                <div className="album-tracks-track imageFirst container ">
                  <Link
                    to={`/${appStore.language}/small-tragedies/${tracks[1].slug}`}
                    className="trackImage withPreview"
                  >
                    <img src={tracks[1].indexImageUrl} alt={tracks[1].title} />
                    {showVideo && (
                      <video
                        src="https://klezmeryunan.com/media/albums/small_tragedies/tracks/previews/preview_1.mp4"
                        autoPlay
                        loop
                        playsInline
                        muted
                      />
                    )}
                  </Link>
                  <div className="trackTexts padL-sm">
                    <div className="album-tracks-track-number">2.</div>
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[1].slug}`}
                      className="album-tracks-track-title"
                    >
                      {tracks[1].title}
                    </Link>
                    {tracks[1].trackComposer && (
                      <div className="album-tracks-track-composer">{tracks[1].trackComposer}</div>
                    )}
                    <div
                      className="album-tracks-track-text width-m"
                      dangerouslySetInnerHTML={{__html: tracks[1].shortDescription}}
                    />

                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[1].slug}`}
                      className="album-tracks-track-link"
                    >
                      {label('videoInfo', appStore.language)}
                    </Link>
                  </div>
                </div>
              </div>
              <div id={tracks[2].slug} />
              <div className="row">
                <div className="album-tracks-track container padLR">
                  <div className="trackTexts">
                    <div className="album-tracks-track-number">3.</div>
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[2].slug}`}
                      className="album-tracks-track-title"
                    >
                      {tracks[2].title}
                    </Link>
                    {tracks[2].trackComposer && (
                      <div className="album-tracks-track-composer">{tracks[2].trackComposer}</div>
                    )}
                    <div
                      className="album-tracks-track-text width-m"
                      dangerouslySetInnerHTML={{__html: tracks[2].shortDescription}}
                    />
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[2].slug}`}
                      className="album-tracks-track-link"
                    >
                      {tracks[2].youtubeId ? label('videoInfo', appStore.language) : label('info', appStore.language)}
                    </Link>
                  </div>

                  <Link to={`/${appStore.language}/small-tragedies/${tracks[2].slug}`} className="trackImage">
                    <img src={tracks[2].indexImageUrl} alt={tracks[2].title} />
                  </Link>
                </div>
              </div>

              <div>
                <div className="anchor" id={tracks[3].slug} />
                <div className="anchor" id={tracks[4].slug} />
                <div className="anchor" id={tracks[5].slug} />
              </div>

              <div className="row isDark">
                <div className="groupTracks padLR">
                  <div className="album-tracks-track container ">
                    <div className="trackTexts">
                      <div className="album-tracks-track-number">4.</div>
                      <Link
                        to={`/${appStore.language}/small-tragedies/${tracks[3].slug}`}
                        className="album-tracks-track-title"
                      >
                        {tracks[3].title}
                      </Link>
                      {tracks[3].trackComposer && (
                        <div className="album-tracks-track-composer">{tracks[3].trackComposer}</div>
                      )}{' '}
                      <div
                        className="album-tracks-track-text width-m"
                        dangerouslySetInnerHTML={{__html: tracks[3].shortDescription}}
                      />
                      <Link
                        to={`/${appStore.language}/small-tragedies/${tracks[3].slug}`}
                        className="album-tracks-track-link"
                      >
                        {tracks[3].youtubeId ? label('videoInfo', appStore.language) : label('info', appStore.language)}
                      </Link>
                    </div>
                  </div>
                  <div className="album-tracks-track container">
                    <div className="trackTexts">
                      <div className="album-tracks-track-number">5.</div>
                      <Link
                        to={`/${appStore.language}/small-tragedies/${tracks[4].slug}`}
                        className="album-tracks-track-title"
                      >
                        {tracks[4].title}
                      </Link>
                      {tracks[4].trackComposer && (
                        <div className="album-tracks-track-composer">{tracks[4].trackComposer}</div>
                      )}{' '}
                      <div
                        className="album-tracks-track-text width-m"
                        dangerouslySetInnerHTML={{__html: tracks[4].shortDescription}}
                      />
                      <Link
                        to={`/${appStore.language}/small-tragedies/${tracks[4].slug}`}
                        className="album-tracks-track-link"
                      >
                        {tracks[4].youtubeId ? label('videoInfo', appStore.language) : label('info', appStore.language)}
                      </Link>
                    </div>
                  </div>
                  <div className="album-tracks-track container ">
                    <div className="trackTexts">
                      <div className="album-tracks-track-number">6.</div>
                      <Link
                        to={`/${appStore.language}/small-tragedies/${tracks[5].slug}`}
                        className="album-tracks-track-title"
                      >
                        {tracks[5].title}
                      </Link>
                      {tracks[5].trackComposer && (
                        <div className="album-tracks-track-composer">{tracks[5].trackComposer}</div>
                      )}{' '}
                      <div
                        className="album-tracks-track-text width-m"
                        dangerouslySetInnerHTML={{__html: tracks[5].shortDescription}}
                      />
                      <Link
                        to={`/${appStore.language}/small-tragedies/${tracks[5].slug}`}
                        className="album-tracks-track-link"
                      >
                        {tracks[5].youtubeId ? label('videoInfo', appStore.language) : label('info', appStore.language)}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div id={tracks[6].slug} />
              <div className="row">
                <div className="album-tracks-track container padLR">
                  <div className="trackTexts">
                    <div className="album-tracks-track-number">7.</div>
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[6].slug}`}
                      className="album-tracks-track-title"
                    >
                      {tracks[6].title}
                    </Link>
                    {tracks[6].trackComposer && (
                      <div className="album-tracks-track-composer">{tracks[6].trackComposer}</div>
                    )}
                    <div
                      className="album-tracks-track-text"
                      dangerouslySetInnerHTML={{__html: tracks[6].shortDescription}}
                    />
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[6].slug}`}
                      className="album-tracks-track-link"
                    >
                      {tracks[6].youtubeId ? label('videoInfo', appStore.language) : label('info', appStore.language)}
                    </Link>
                  </div>
                  <Link
                    to={`/${appStore.language}/small-tragedies/${tracks[6].slug}`}
                    className="trackImage imageLarge"
                  >
                    <img src={tracks[6].indexImageUrl} alt={tracks[6].title} />
                  </Link>
                </div>
              </div>
              <div id={tracks[7].slug} />
              <div className="row">
                <div className="album-tracks-track container padLR">
                  <div className="trackImage imageLarge noIcon"></div>
                  <div className="trackTexts">
                    <div className="album-tracks-track-number">8.</div>
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[7].slug}`}
                      className="album-tracks-track-title"
                    >
                      {tracks[7].title}
                    </Link>
                    {tracks[7].trackComposer && (
                      <div className="album-tracks-track-composer">{tracks[7].trackComposer}</div>
                    )}
                    <div
                      className="album-tracks-track-text"
                      dangerouslySetInnerHTML={{__html: tracks[7].shortDescription}}
                    />
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[7].slug}`}
                      className="album-tracks-track-link"
                    >
                      {tracks[7].youtubeId ? label('videoInfo', appStore.language) : label('info', appStore.language)}
                    </Link>
                  </div>
                </div>
              </div>
              <div id={tracks[8].slug} />
              <div className="row">
                <div className="album-tracks-track container padLR">
                  <div className="trackTexts">
                    <div className="album-tracks-track-number">9.</div>
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[8].slug}`}
                      className="album-tracks-track-title"
                    >
                      {tracks[8].title}
                    </Link>
                    {tracks[8].trackComposer && (
                      <div className="album-tracks-track-composer">{tracks[8].trackComposer}</div>
                    )}
                    <div
                      className="album-tracks-track-text"
                      dangerouslySetInnerHTML={{__html: tracks[8].shortDescription}}
                    />
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[8].slug}`}
                      className="album-tracks-track-link"
                    >
                      {tracks[8].youtubeId ? label('videoInfo', appStore.language) : label('info', appStore.language)}
                    </Link>
                  </div>
                  <Link to={`/${appStore.language}/small-tragedies/${tracks[8].slug}`} className="trackImage">
                    <img src={tracks[8].indexImageUrl} alt={tracks[8].title} />
                  </Link>
                </div>
              </div>
              <div id={tracks[9].slug} />
              <div className="row">
                <div className="album-tracks-track container padLR">
                  <div className="trackImage imageLarge noIcon"></div>
                  <div className="trackTexts">
                    <div className="album-tracks-track-number">10.</div>
                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[9].slug}`}
                      className="album-tracks-track-title"
                    >
                      {tracks[9].title}
                    </Link>
                    {tracks[9].trackComposer && (
                      <div className="album-tracks-track-composer">{tracks[9].trackComposer}</div>
                    )}
                    <div
                      className="album-tracks-track-text"
                      dangerouslySetInnerHTML={{__html: tracks[9].shortDescription}}
                    />

                    <Link
                      to={`/${appStore.language}/small-tragedies/${tracks[9].slug}`}
                      className="album-tracks-track-link"
                    >
                      {tracks[9].youtubeId ? label('videoInfo', appStore.language) : label('info', appStore.language)}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row isDark credits">
            <div className="album-credits ">
              <div className="album-credits-col">
                <div className="album-credits-col-texts" dangerouslySetInnerHTML={{__html: data.album.credits1}} />
              </div>
              <div className="album-credits-col">
                <div className="album-credits-col-texts col2" dangerouslySetInnerHTML={{__html: data.album.credits2}} />
              </div>
              <div className="album-credits-col">
                <div className="album-credits-col-texts col3" dangerouslySetInnerHTML={{__html: data.album.credits3}} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {setCurrentPage, setFooterIsDark} from '../../store/app/events';
import $appStore from '../../store/app/store';
import config from '../../config/config.json';
import {useAxios} from '../../hooks/useAxios';
import {ContactForm} from './ContactForm';

import './About.scss';

export const About = () => {
  const appStore = useStore($appStore);
  const {data, error} = useAxios(`${config.apiURL}/about`, 'POST', {language: appStore.language});

  useEffect(() => {
    setFooterIsDark(false);
    setCurrentPage(`about`);
  }, []);

  return (
    <div className="about">
      {data && (
        <div className="about-top isDark">
          <div className="about-top-container padLR">
            <div className="about-top-container-texts">
              <div className="about-top-container-texts-title">{data.about.title}</div>
              <div className="about-top-container-texts-text" dangerouslySetInnerHTML={{__html: data.about.text}} />
            </div>
            <div className="about-top-container-figures">
              <svg className="icon">
                <use xlinkHref="#figures" />
              </svg>
            </div>
          </div>
        </div>
      )}
      {data && (
        <div className="about-content">
          <div className="about-content-curve"></div>
          {data.about.texts.map((item: any, index: number) => (
            <div className={`about-content-item ${item.backgroundColor ? item.backgroundColor : ''}`} key={index}>
              <div className="about-content-item-container padLR">
                {item.title && <div className="about-content-item-title">{item.title}</div>}
                {item.text && <div className="about-content-item-text" dangerouslySetInnerHTML={{__html: item.text}} />}
                {item.text2 && (
                  <div className="about-content-item-text2" dangerouslySetInnerHTML={{__html: item.text2}} />
                )}
                {item.images && item.images.length > 0 && (
                  <div className="about-content-item-images">
                    {item.images.map((image: any, index: number) => (
                      <div className={`about-content-item-images-image style-${image.style}`} key={index}>
                        <img src={image.url} alt={item.title} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <ContactForm />
    </div>
  );
};
